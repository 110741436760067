/**
 * @file standalone/content_block_overlay.scss
 *
 * \brief Library file - shared styles for slide panel blocks
 *
 */

@import '../theme-bootstrap';

.content-block__overlay {
  position: fixed;
  background-size: cover;
  z-index: 10001;
  transition: transform 0.5s, opacity 0.3s;
  transform: translateY(100%);
  opacity: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-align: center;
  -webkit-overflow-scrolling: touch;
  top: 0;
  #{$rdirection}: 0;
  background-color: $color-cream;
  &--backdrop {
    transition: opacity 0.3s;
    position: fixed;
    z-index: 10000;
    top: 0;
    #{$ldirection}: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    background: $color-grey-transparent-75;
  }
  &--close {
    padding: 0 24px;
    text-align: center;
    position: sticky;
    z-index: 999;
    &.icon-left {
      text-align: $ldirection;
    }
    &.icon-right {
      text-align: $rdirection;
    }
    &-btn {
      border: none;
      background: none;
      padding-bottom: 20px;
    }
    .icon--gift_close {
      width: 25px;
      height: 25px;
      stroke-width: 0.5;
      stroke: $color-black;
    }
  }
  &--cta {
    cursor: pointer;
  }
}

.content-block-overaly-open {
  overflow: hidden;
  .content-block__overlay.active {
    transform: translateY(0);
    opacity: 1;
    height: 100vh;
    padding: 24px 0;
    overflow-y: auto;
    background: $color-cream;
    .gallery-item__content-arrows {
      bottom: 0;
      height: 35px;
      @include breakpoint($landscape-up) {
        bottom: inherit;
      }
    }
    .gallery-item__content-count {
      bottom: 0;
    }
    &.full-display {
      padding: 0;
      height: 100vh;
      min-height: 100%;
      height: -webkit-fill-available;
      @include breakpoint($landscape-up) {
        overflow: hidden;
      }
      .gallery-formatter {
        height: 100vh;
        padding: 0;
        margin: 0;
        overflow-y: auto;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
        margin-bottom: -24px;
        @include breakpoint($landscape-up) {
          padding: 17vh 0;
          margin: 0;
        }
        @include breakpoint($extralarge-up) {
          padding: 150px 0;
        }
      }
      .gallery-item__img-mob-width .mantle-media-asset img {
        max-width: 100%;
        width: 100%;
        height: auto;
      }
      .content-block__overlay--close {
        position: absolute;
        transform: translateX(-50%);
        #{$ldirection}: 50%;
        top: 40px;
        z-index: 1;
        @if $is-rtl == true {
          transform: translateX(50%)
        }
        &.icon-left {
          #{$ldirection}: 0;
          transform: none;
        }
        &.icon-right {
          #{$rdirection}: 0;
          transform: none;
        }
      }
      .gallery-item {
        margin-bottom: 0;
      }
      .gallery-item__content-count {
        bottom: 47px;
        @include breakpoint($landscape-up) {
          bottom: -12vh;
        }
        @include breakpoint($extralarge-up) {
          bottom: -100px;
        }
      }
      .gallery-item__node-block .tasting-notes-formatter__carousel-item {
        @include breakpoint($landscape-up) {
          width: calc(100% / 3);
          padding: 10px 0;
        }
      }
      .gallery-item__node-block .tasting-notes-formatter__carousel {
        @include breakpoint($landscape-up) {
          padding: 0;
          width: 60vw;
          max-width: 900px;
        }
      }
      .tasting-notes {
        @include breakpoint($landscape-up) {
          justify-content: center;
        }
      }
      .gallery-item__content-arrows {
        bottom: 20px;
        @include breakpoint($landscape-up) {
          bottom: inherit;
        }
        .gallery-item__content-arrows--right,
        .gallery-item__content-arrows--left {
          top: auto;
          #{$ldirection}: 21px;
          #{$rdirection}: auto;
          @include breakpoint($landscape-up) {
            top: 50%;
          }
        }
        .gallery-item__content-arrows--right {
          #{$rdirection}: 21px;
          #{$ldirection}: auto;
        }
      }
      .gallery-item__img {
        flex-grow: unset;
        width: 100%;
        @include breakpoint($landscape-up) {
          flex-grow: 1;
        }
        video,
        img {
          width: 100%;
          @include breakpoint($landscape-up) {
            width: auto;
          }
        }
        &.gallery-item__img-mob-width {
          width: auto;
          video,
          img {
            width: auto;
          }
        }
      }
    }
    &.full-display__text-media {
      .gallery-item {
        padding: 50px 0;
        @include breakpoint($landscape-up) {
          padding: 0;
        }
      }
      .gallery-item__text {
        order: 1;
      }
      .gallery-item__img {
        order: 2;
      }
      .content-block__line--content {
        padding-bottom: 20px;
      }
      .gallery-formatter {
        @include breakpoint($landscape-up) {
          padding: 13vh 0;
        }
        @include breakpoint($extralarge-up) {
          padding: 115px 0;
        }
      }
      .gallery-item__content-arrows {
        @include breakpoint($landscape-up) {
          bottom: inherit;
        }
      }
      .gallery-item__content-count {
        bottom: 25px;
        @include breakpoint($landscape-up) {
          bottom: -8vh;
        }
        @include breakpoint($extralarge-up) {
          bottom: -50px;
        }
      }
    }
  }
  .gnav-header-block__inner {
    z-index: 0;
  }
  .blossoms2-product-modal {
    display: block;
  }
}
